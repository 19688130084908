@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100vh;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.sidebar::after {
  content: "";
  position: absolute;
  top: 0;
  right: 3.5px;
  width: 1px;
  height: 100%;
  @apply bg-gray-400;
  z-index: -1;
}

.custom-scrollbar::-webkit-scrollbar {
  width: 8px;
}

.custom-scrollbar::-webkit-scrollbar-track {
  border-radius: 10px;
  width: 1px;
}

.custom-scrollbar::-webkit-scrollbar-thumb {
  @apply bg-gray-500;
  width: 8px;
  border-radius: 10px;
  margin-left: 3.5px; /* Centers the 8px thumb on the 1px track */
}

.custom-scrollbar::-webkit-scrollbar-thumb:hover {
  background: #555;
}